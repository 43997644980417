<template>
  <div class="person-box">
    <div class="bread">
      <div class="nav-box">
        <router-link class="nav-title" to="/pages/index/index?columnId=452">路演</router-link>
        <span>&gt;</span>
        <a class="nav-title" @click="reloadPage">个人中心</a>
      </div>
    </div>
    <div class="person">

      <div class="person-module">
        <span :class="{'person-module-item': true, 'infomation': true, 'person-module-item-active': activeId == '0'}" @click="changeTab('0')">
          基本信息
        </span>
        <span v-if="!isCompany" :class="{'person-module-item': true, 'news': true, 'person-module-item-active': activeId == '1'}" @click="changeTab('1')" userNews.sync="personNews">
          我的消息<template v-if="personNews">({{personNews}})</template>
        </span>
        <span v-if="!isCompany" :class="{'person-module-item': true, 'follow': true, 'person-module-item-active': activeId == '2'}" @click="changeTab('2')">
          我的关注
        </span>
        <!-- 企业回复 -->
        <span v-if="isCompany" :class="{'person-module-item': true, 'person-module-item-active': activeId == '3'}" @click="changeTab('3')" companyNews.sync="companyNews">
          我的消息<template v-if="companyNews">({{companyNews}})</template>
        </span>
      </div>
      <!-- 基本信息 -->
      <Information v-show="activeId=='0'" :isCompany="isCompany" />
      <!-- 我的消息 个人版 -->
      <PersonalNews v-if="!isCompany" v-show="activeId=='1'" :activeId='activeId' :personNews.sync="personNews" />
      <!-- 我的关注 -->
      <Follow v-if="!isCompany" v-show="activeId=='2'" />
      <!-- 我的消息  企业版-->
      <EnterpriseNews v-if="isCompany" v-show="activeId=='3'" :activeId='activeId' :companyNews.sync="companyNews" />
    </div>
  </div>
</template>
<script>
import "@/assets/css/person.less";
import { setCatalogTree } from "@/utils/store-data";

import Information from "./components/information";
import PersonalNews from "./components/personalNews";
import Follow from "./components/follow";
import EnterpriseNews from "./components/enterpriseNews";
export default {
  data() {
    return {
      activeId: "0", //当前所在tab
      personNews: 0,
      companyNews: 0
    };
  },
  computed: {
    isCompany() {
      var token = this.$store.state.token;
      return token && token.indexOf("BC_") != -1;
    }
  },
  components: { Information, PersonalNews, Follow, EnterpriseNews },
  created() {
    setCatalogTree(this.$store);
    if(!this.$store.state.token) this.$router.push('/login')
  },
  methods: {
    changeTab(data) {
      this.activeId = data;
    },
    reloadPage(){
      this.$router.go(0);
    },
  }
};
</script>