<template>
  <div class="person-infomation">
    <div class="person-infomation-item">
      <span class="person-infomation-label">头像</span>
      <img v-if="!errorPg && (isCompany? userDetail.logo :userDetail.portrait)" :src="`${isCompany? userDetail.logo : userDetail.portrait}`" class="person-infomation-img" alt="头像" @error="errorPg = true"/>
      <img v-else-if="(errorPg || !userDetail.logo) && isCompany " src="~@/assets/img/person/company.svg" class="person-infomation-img" alt="头像" />
      <img v-else-if="(errorPg || !userDetail.portrait) && !isCompany" src="~@/assets/img/person/person.svg" class="person-infomation-img" alt="头像"/>
      <!-- <span class="person-infomation-picture" v-else>
        <span class="person-infomation-picture-icon"></span>
      </span> -->
      
      <div class="person-infomation-upload">
        <p id="test1" class="person-infomation-upload-button">
          <span class="person-infomation-upload-icon"></span>
          {{(isCompany? userDetail.logo :userDetail.portrait) ? '更换头像': '上传头像'}}
        </p>
        <span class="person-infomation-upload-remarks" >支持图片类型：png, jpg</span>
      </div>
    </div>
    <div class="person-infomation-item person-infomation-name">
      <span class="person-infomation-label">登录名</span>
      <span class="person-infomation-name-text">{{userDetail.username}}</span>
    </div>
    <div class="person-infomation-item person-infomation-password">
      <span class="person-infomation-label">修改密码</span>
      <span class="person-infomation-password-text">*********</span>
      <span class="person-infomation-edit-icon" @click="edit('password')" v-if="!editPassword"></span>
      <div class="person-infomation-edit-box" v-if="editPassword">
        <div class="person-infomation-edit-item">
          <div class="person-infomation-edit-label">
            <span>*</span>旧密码
          </div>
          <input placeholder="请输入旧密码" v-model="changeUser.oldLoginPwd" @blur="blur('oldPwd')" type="password" autocomplete="off"/>
          <div class="error-tip" v-if="oldPwdRule">{{oldPwdRule}}</div>
        </div>
        <div class="person-infomation-edit-item">
          <div class="person-infomation-edit-label">
            <span>*</span>新密码
          </div>
          <input placeholder="请输入新密码" v-model="changeUser.newLoginPwd" maxlength="16" @blur="blur('newPwd')" type="password" autocomplete="off"/>
          <div class="error-tip" v-if="newPwdRule">{{newPwdRule}}</div>
        </div>
        <div class="person-infomation-edit-item">
          <div class="person-infomation-edit-label">
            <span>*</span>确认新密码
          </div>
          <input placeholder="请确认新密码" v-model="changeUser.confirmoNewLoginPwd" maxlength="16" @blur="blur('confirmPwd')" type="password" autocomplete="off"/>
          <div class="error-tip" v-if="confirmPwdRule">{{confirmPwdRule}}</div>
        </div>
        <div class="person-infomation-edit-buttons">
          <span class="person-infomation-edit-cancle" @click="cancle('password')">取消</span>
          <span class="person-infomation-edit-submit" @click="updatePwd">保存</span>
        </div>
      </div>
    </div>
    <div class="person-infomation-item person-infomation-tel" v-if="!isCompany">
      <span class="person-infomation-label">手机号</span>
      <span class="person-infomation-tel-text">{{userDetail.userNewname}}</span>
      <span class="person-infomation-tel-remarks">（此号码可直接用于登录）</span>
    </div>
    <div class="person-infomation-item person-infomation-nickname">
      <span class="person-infomation-label">昵称</span>
      <span class="person-infomation-nickname-text" v-if="!editNickname && !isCompany">{{userDetail.custName }}</span>
      <span class="person-infomation-nickname-text" v-else>{{userDetail.name }}</span>
      <span class="person-infomation-edit-icon" @click="edit('nickname')" v-if="!editNickname && !isCompany"></span>
      <div class="person-infomation-edit-box" v-if="editNickname">
        <div class="person-infomation-edit-item">
          <div class="person-infomation-edit-label">
            <span>*</span>新昵称
          </div>
          <input placeholder="请输入新昵称" v-model="changeUser.custName" @blur="blur('nickname')"/>
          <div class="error-tip">{{nickNameRule}}</div>
        </div>
        <div class="person-infomation-edit-buttons">
          <span class="person-infomation-edit-cancle" @click="cancle('nickname')">取消</span>
          <span class="person-infomation-edit-submit" @click="updateNickName">保存</span>
        </div>
      </div>
    </div>
    <div class="logout" @click="logout">退出登录</div>
  </div>
</template>
<script>
import { Encrypt } from '@/utils/cryptoJS'
import interfaceBack from '@/mixins/interfaceBack.js'
import { updateUserInfo, uploadBusinessInfo, updatePwd } from "@/api/login"
import BuryingPont from "@/mixins/buryingPoint.js";
export default {
  mixins: [interfaceBack,BuryingPont],
  data() {
    return {
      editPassword: false,
      editNickname: false,

      userDetail: {},
      changeUser: {
        oldLoginPwd: '',  //旧密码
        newLoginPwd: '',  //新密码
        confirmoNewLoginPwd: '',  //确认新旧密码

        custName: '',  //昵称
      },

      oldPwdRule: '',
      newPwdRule: '',
      confirmPwdRule: '',
      nickNameRule: '',
      errorPg: false
    };
  },
  props: {
    isCompany: {
      type: Boolean,
      default: false
    }
  },
  created() {
    
  },
  mounted() {
    var layui = window.layui
    var _this = this
    var baseUrl = this.$store.state.baseUrl
    var type = this.isCompany ? 'logo' : '1'
    layui.use('upload', function(){
      var upload = layui.upload;
      //执行实例
      upload.render({
        elem: '#test1',
        accept: 'images',
        // acceptMime: 'image/jpg, image/png',
        acceptMime: 'image/*',
        // acceptMime: 'image/png',
        exts: 'jpg|png',
        headers: {
          Authorization: _this.$store.state.token
        },
        url: baseUrl+"/f/cnfic/xhcj/toC/upload?fileType="+type,   //fileType=1是个人,fileType=logo是企业
        done: function(res){
          if(res.code == 0) {
            _this.updateUserDetail()
            if(type == 1){
              // 个人修改头像埋点 
              _this.sensors.track( 'LY_person_change_avatar',{
                AppName: _this.$store.state.AppName, 
                is_login: true, 
                platform: _this.$store.state.platform
              })
            }else{
              // 企业修改头像埋点 
              _this.sensors.track( 'LY_company_change_avatar',{
                AppName: _this.$store.state.AppName, 
                is_login: true, 
                platform: _this.$store.state.platform
              })
            }
            
          }else if(res.code == 601){
            if(_this.isCompany) {
              _this.$store.dispatch('logBusinessOut')
            } else {
              _this.$store.dispatch('logToCOut')
            }
            _this.$message({tipTxt:'您的登录信息已过期，请',isLink:true,loginTxt:'重新登录',isClose:true,isReload:true});
          }
        }
      });
    });
    if(this.$store.state.token) {
      this.updateUserDetail()
    }
  },
  methods: {
    //更新密码
    updatePwd() {
      const {oldLoginPwd, newLoginPwd, confirmoNewLoginPwd} = this.changeUser
      // const {username} = this.userDetail
      this.blur('oldPwd')
      this.blur('newPwd')
      this.blur('confirmPwd')
      if(!this.oldPwdRule && !this.newPwdRule && !this.confirmPwdRule) {
        if(this.isCompany) {
          uploadBusinessInfo({
            oldPassword: Encrypt(oldLoginPwd, this.$store.state.businessPKI),
            newPassword: Encrypt(newLoginPwd, this.$store.state.businessPKI),
          }).then((res) => {
            if(res.code == 0){
              this.cancle('password')
              // this.updateUserDetail()
              // this.logout()
              this.$store.dispatch('logBusinessOut')
              this.$message({tipTxt:'您的密码修改成功，请',isLink:true,loginTxt:'重新登录',isClose:true});
              // 企业修改密码埋点 
              this.sensors.track( 'LY_company_change_password',{
                AppName: this.$store.state.AppName, 
                is_login: true, 
                platform: this.$store.state.platform
              })
            }else if(res.code == 2016){
              this.oldPwdRule = '请输入正确的密码';
            }else{
              this.interfaceBack(res.code,res.msg);
            }
          }).catch(error => {
            this.interfaceBack(error.code,error.msg);
          })
        } else {
          updatePwd({
            // userName: username,
            oldPwd: Encrypt(oldLoginPwd, this.$store.state.businessPKI),
            newPwd: Encrypt(newLoginPwd, this.$store.state.businessPKI),
            newConfirmPwd: Encrypt(confirmoNewLoginPwd, this.$store.state.businessPKI),
            // pwdType: 2,
            // channelNo: 'XFAP'
          }).then((res) => {
            if(res.code === 200){
              this.cancle('password')
              // this.updateUserDetail()
              // this.logout()
              this.$store.dispatch('logToCOut')
              this.$message({tipTxt:'您的密码修改成功，请',isLink:true,loginTxt:'重新登录',isClose: true});
              // 个人修改密码埋点 
              this.sensors.track( 'LY_person_change_password',{
                AppName: this.$store.state.AppName, 
                is_login: true, 
                platform: this.$store.state.platform
              })
            }else if(res.code === 2016) {
              this.oldPwdRule = '请输入正确的密码';
            } else {
              this.interfaceBack(res.code,res.msg);
            }
          }).catch(error => {
            this.interfaceBack(error.code,error.msg);
          })
        }
      }
    },
    //更新昵称
    updateNickName() {
      const { id } = this.userDetail
      const { custName } = this.changeUser
      this.blur('nickname')
      if(!this.nickNameRule) {
        updateUserInfo({
          id: id,
          custName: custName,
          // channelNo: 'XFAP'
        }).then((res) => {
          if(res.code == 0){
            this.cancle('nickname')
            this.updateUserDetail()
            // 个人修改昵称埋点 
            this.sensors.track( 'LY_change_nickname',{
              AppName: this.$store.state.AppName, 
              is_login: true, 
              platform: this.$store.state.platform
            })
          }else{
            this.$message({tipTxt:res.msg})
          }
        }).catch(error => {
          this.nickNameRule = error.msg
        })
      }
    },
    //获取用户详情
    getUserDetail() {
      this.userDetail = {}
      console.log(this.$store.state.localInfoMsg)
      Object.assign(this.userDetail, this.$store.state.localInfoMsg)
      if(this.userDetail.username && !this.isCompany) {
        this.userDetail.userNewname = this.userDetail.username.substr(0, 3)+'****'+this.userDetail.username.substr(7, 10)
      }
    },
    async updateUserDetail() {
      if(!this.isCompany) {
        await this.$store.dispatch('getUserInfo')
      } else {
        await this.$store.dispatch('getBusinessInfo')
      }
      await this.getUserDetail()
    },
    //点击编辑按钮
    edit(type) {
      switch(type) {
        case 'password': this.editPassword = true;break;
        case 'nickname': this.editNickname = true;break;
      }
    },
    blur(type) {
      var reg = this.$store.state.psdRegexp;
      switch(type) {
        case'oldPwd': if(!this.changeUser.oldLoginPwd) {
          this.oldPwdRule = '请输入旧密码'
        } else {
          this.oldPwdRule = ''
        }
        break;
        case 'newPwd': if(this.changeUser.newLoginPwd) {
          var newCount = 0;
          for(var i in reg){
            if(reg[i].test(this.changeUser.newLoginPwd)){
              newCount++;
            }
          }
          if(newCount<2||this.changeUser.newLoginPwd.length<8||this.$store.state.noPsdRegexp.test(this.changeUser.newLoginPwd)){
            this.newPwdRule = '8-16位密码(包含大写、小写字母、数字和特殊字符其中两种)'
          } else if(this.changeUser.newLoginPwd != this.changeUser.confirmoNewLoginPwd) {
            this.confirmPwdRule = '两次密码请保持一致'
            this.newPwdRule = '两次密码请保持一致'
          } else {
            this.newPwdRule = ''
            this.confirmPwdRule = ''
          }
        } else {
          this.newPwdRule = '请输入新密码'
        }
        break;
        case 'confirmPwd':if(this.changeUser.confirmoNewLoginPwd){
          var confrimCount = 0;
          for(var j in reg){
            if(reg[j].test(this.changeUser.confirmoNewLoginPwd)){
              confrimCount++;
            }
          }
          if(confrimCount<2||this.changeUser.confirmoNewLoginPwd.length<8||this.$store.state.noPsdRegexp.test(this.changeUser.confirmoNewLoginPwd)) {
            this.confirmPwdRule = '8-16位密码(包含大写、小写字母、数字和特殊字符其中两种)'
          } else if(this.changeUser.newLoginPwd != this.changeUser.confirmoNewLoginPwd) {
            this.confirmPwdRule = '两次密码请保持一致'
            this.newPwdRule = '两次密码请保持一致'
          } else {
            this.newPwdRule = ''
            this.confirmPwdRule = ''
          }
        } else {
          this.confirmPwdRule = '请确认新密码'
        }break;
        case 'nickname':if(this.changeUser.custName){
          if(this.changeUser.custName.length<2||this.changeUser.custName.length>16){
            this.nickNameRule = '昵称长度为2~16个字符'
          }else{
            this.nickNameRule = ''
          }
        } else {
          this.nickNameRule = '请输入新昵称'
        }
      }
    },
    async logout() {
       if(this.isCompany) {
        await this.$store.dispatch('logBusinessOut')
       } else {
        await this.$store.dispatch('logToCOut')
       }
       this.$router.push('/login')
       // 点击退出登录埋点
      this.sensors.track( 'LY_login_out',{
        AppName: this.$store.state.AppName, 
        is_login: true, 
        platform: this.$store.state.platform
      })
    },
    //点击编辑按钮
    cancle(type) {
      switch(type) {
        case 'password': this.editPassword = false;
        Object.assign(this.changeUser, {
          oldLoginPwd: '',  //旧密码
          newLoginPwd: '',  //新密码
          confirmoNewLoginPwd: '',  //确认新旧密码
        })
        this.oldPwdRule = ''
        this.confirmPwdRule = ''
        this.confirmPwdRule = ''
        ;break;
        case 'nickname': this.editNickname = false;
        Object.assign(this.changeUser, {
          custName: ''
        })
        this.nickNameRule = ''
        break;
      }
    }
  },
  watch:{
    'changeUser.custName':{
      handler(newVal){
        if(newVal.length<2||newVal.length>16){
          this.nickNameRule = '昵称长度为2~16个字符'
        }else{
          this.nickNameRule = ''
        }
      }
    }
  }
};
</script>
