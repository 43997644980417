<template>
  <!-- 确认删除弹框 -->
  <div class="del-model" v-show="delFlag">
    <div class="feedback_success">
      <div class="feedback_title">提示</div>
      <p class="abnormal-tips">确认删除？</p>
      <div>
        <span class="feedback_success_button" @click="cancelData">取消</span>
        <span class="feedback_success_button confirm" @click="delData">确认</span>
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  props: {
    delFlag:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{}
  },
  methods: {
    cancelData(){
      this.$emit('delCancel', false);
    },
    delData(){
      this.$emit('delConfirm', true);
    }
  }
}
</script>
<style lang="less" scoped>
.del-model {
  // .transition(all .5s);
  position: fixed;
  // _position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /**遮罩全屏top,left都为0,width,height为100%**/
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9998;
  -moz-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
  .feedback_success {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 26px 0 0 0;
    transform: translate(-50%, -50%);
    width: 332px;
    height: 224px;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    .feedback_title {
      line-height: 24px;
      font-size: 24px;
      color: #141825;
    }
    .abnormal-tips {
      margin: 50px 0 59px 0;
      font-size: 14px;
      color: #141825;
    }
    .feedback_success_button {
      margin: 0 10px;
      display: inline-block;
      width: 120px;
      height: 40px;
      background: #e5e5e5;
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      color: #666666;
      line-height: 40px;
      cursor: pointer;
      &.confirm{
        color: #ffffff;
        background: #005BAC;
      }
    }
  }
}
</style>