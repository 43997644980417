<template>
  <div class="enterprise-news">
    <ul class="enterprise-news-list" v-if="companyNewsList.length">
      <li class="enterprise-news-item" v-for="(item, index) in companyNewsList" :key="item.id">
        <!-- <span class="enterprise-news-item-picture"></span> -->
        <img class="enterprise-news-item-picture" :src="`${item.logo}`" alt="logo" v-if="item.logo && !item.errorPg" @error="item.errorPg = true"/>
        <img class="enterprise-news-item-picture" src="~@/assets/img/person/person.svg" alt="logo" v-else/>
        <div class="enterprise-news-item-content">
          <p class="enterprise-news-item-name">投资者提问：</p>
          <p class="enterprise-news-item-value">
            {{item.content}}
          </p>
          <p class="enterprice-news-item-bottom">
            <span class="enterprise-news-item-time">{{item.createTime}}</span>
            <span class="enterprice-news-item-button enterprice-news-item-reply" v-if="!item.replyContent && !item.showReply" @click="clickReply(item, index)">回复</span>
            <span class="enterprice-news-item-more enterprice-news-item-view-reply" v-if="item.replyContent && !item.showReply" @click="clickReply(item, index)">查看回复内容</span>
          </p>
          <div class="enterprise-news-item-reply-edit" v-if="!item.replyContent && item.showReply">
            <p class="enterprise-news-item-reply-title">
              信息回复：
            </p>
            <div class="textarea-box">
               <textarea placeholder="请输入回复信息" v-model="item.replyCopyContent" maxlength="1000"></textarea>
               <span class="textarea-length">{{item.replyCopyContent.length}}/1000</span>
               <span v-if="item.noContent" class="no-content">请输入回复内容</span>
            </div>
            
            <p class="enterprise-news-item-reply-bottom">
              <!-- <span class="enterprise-news-item-reply-bottom-time">2021/01/23 10:57</span> -->
              <span class="enterprice-news-item-reply-bottom-button" @click="companyReplyUser(item)">发送</span>
            </p>
          </div>
          <div class="enterprise-news-item-reply-noEdit" v-if="item.replyContent && item.showReply">
            <p>回复</p>
            <p class="enterprise-news-item-reply-noEdit-content">
              {{item.replyContent}}
            </p>
            <div class="enterprise-news-item-reply-noEdit-bottom">
              <span class="time">{{item.updateTime}}</span>
              <span class="del" @click="delCompanyItem(item)"></span>
            </div>
          </div>
        </div>
      </li>
      
    </ul>
    <NoData v-else/>
    <DelModel :delFlag="delFlag" @delCancel="delCancel" @delConfirm="delConfirm"/>
    <p class="loading-more" v-if="!finished && !loadMore && companyNewsList.length && form.pageNo>=2 " @click="getMore">加载更多</p>
    <p class="load-finish" v-if="finished && companyNewsList.length  && form.pageNo>2">加载完毕</p>
    <p class="loading-data" v-if="loadMore && companyNewsList.length"><img src="@/assets/img/article_list/loading.gif"/>加载中...</p>
  </div>
</template>
<script>
import NoData from "@/components/NoData";
import { getNewsUnread, getCompanyList, delCompanyItem, companyReplyUser, updateNewsStatus } from "@/api/person";
import DelModel from '@/components/delModel'
export default {
  data() {
    return {
      replyType: "look",
      replyShow: false,

      form: {
        pageNo: 1,
        pageSize: 6,
        total: 0
      },
      companyNewsList: [],
      loading: true,
      loadMore: false,
      finished: false,
      delFlag: false,
      delId: null,
      loadFlag: false
    };
  },
  components: {NoData,DelModel},
  props: {
    activeId: {
      type: String,
      default: '0'
    },
    companyNews: {
      type: Number,
      default: 0
    }
  },
  watch: {
    activeId(newVal, oldVal) {
      if(oldVal == '3') {
        window.removeEventListener('scroll', this.scrollFns)
      }
      if(newVal == '3') {
        this.updateNewsStatus()
        window.addEventListener('scroll', this.scrollFns)
        this.loadSecond();
      }
    }
  },
  created() {
    if(this.$store.state.token) {
      this.getNewsUnread();
      this.getCompanyList();
    }
  },
  methods: {
    clickReply(item, index) {
      this.companyNewsList[index].showReply = true
      if(item.isNews) {
        this.updateNewsStatus(item.id)
      }
    },
    getMore() {
      this.loading = true
      this.finished = false
      this.loadMore = true
      this.form.pageNo++
      this.getCompanyList()
    },
    getCompanyList() {
      if(!this.loadFlag){
        this.loadFlag = true;
        const { pageNo, pageSize } = this.form;
        getCompanyList({
          pageNo,
          pageSize
        })
        .then(res => {
          if (res.data && res.data.length) {
            res.data.forEach(item => {
              item.showReply = false
              item.noContent = false
              item.replyCopyContent = ''
              item.errorPg = false
              this.companyNewsList.push(item)
            })
            
            if (this.companyNewsList.length >= res.count) this.finished = true;
            if(this.activeId == '3') this.updateNewsStatus()
          }
        }).finally(() => {
          this.loading = false;
          this.loadMore = false;
          this.loadFlag = false;
        });
      }
    },
    //企业回复  林大哥说先滚动到第一页  
    companyReplyUser(data) {
      if(data.replyCopyContent.trim() == ''){
        data.noContent = true;
      }
      else{
        data.noContent = false;
        companyReplyUser({
          id: data.id,
          replyContent: data.replyCopyContent,
        }).then((res) => {
          if(res.code == 0){
            this.form.pageNo = 1;
            this.finished = false;
            this.companyNewsList = [];
            window.scrollTo(0, 0);
            this.getCompanyList();
          }else{
            this.$message({tipTxt:res.msg,isLink:false});
          }
        })
      }
      
    },
    //删除
    delCompanyItem(data) {
      this.delId = data.id;
      this.delFlag = true;
    },
    // 取消删除
    delCancel(data){
      this.delFlag = data;
    },
    // 确认删除
    delConfirm(data){
      if(data){
        delCompanyItem({
          id: this.delId
        }).then((res) => {
          if(res.code == 0){
            this.form.pageNo = 1;
            this.finished = false;
            this.companyNewsList = [];
            window.scrollTo(0, 0);
            this.getCompanyList();
            this.delFlag = false;
            this.delId = null;
          }
        })
      }
    },
    
    // 2k屏没有滚动条时加载第二页
    loadSecond(){
      var that = this;
      var loadData = setInterval(function(){
        if(!this.finished && !that.loadFlag && that.pageNo < 2){
          that.scrollFns();
        }
        if(that.pageNo >= 2 || this.finished){
          clearInterval(loadData)
        }
      },100)
    },
    // 滚动事件
    scrollFns() {
      var scrollH = window.innerHeight;
      var clientH = document.body.clientHeight;
      var toTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (clientH - scrollH - toTop < 342 && !this.loading && !this.finished && this.form.pageNo<2) {
        this.loading = true;
        this.finished = false;
        this.loadMore = true;
        this.form.pageNo++;

        this.getCompanyList();
      }
    },
    getNewsUnread() {
      getNewsUnread({
        type: 1,
      }).then(res => {
        this.$emit("update:companyNews", res.data);
      });
    },
    //将未读消息转为已读消息
    updateNewsStatus() {
      var list = []
      this.companyNewsList.forEach(item => {
        if(item.status1 == '1') {
          list.push(item.id) 
        }
      })
      if(list.length) {
        updateNewsStatus({
          type: 1,
          id: list.join(',')
        }).then(() => {
          this.companyNewsList.forEach(item => {
            if(item.status1 == '1') {
              item.status1 = '2'
            }
          })
        })
      }
    },
  }
};
</script>
