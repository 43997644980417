import request from "@/utils/request";

/**
 * 我的消息(未读消息数
 * @param {*type} params  1|企业用户 2|投资者用户
 */
export function getNewsUnread(params) {
  return request({
    url: '/f/cnfic/xhcj/myNews/unread',
    params
  })
}

/**
 * 状态修改(未读消息改为已读)
 * @param {*type} params  1|企业用户 2|投资者用户
 * @param {*id} params 
 */
export function updateNewsStatus(params) {
  return request({
    url: '/f/cnfic/xhcj/myNews/updateStatus',
    params
  })
}

/**
 * 查询是否关注
 * @param {*id} params 
 */
export function getAttentionStatus(params) {
  return request({
    url: '/f/cnfic/xhcj/personalCenter/attention/find',
    params
  })
}

/**
 * 获取关注列表
 * @param {*pageNo} data 
 * @param {*pageSize} data 
 * @param {*attentionType} data  10|路演 11|企业
 */
export function getAttentionList(data) {
  return request({
    url: '/f/cnfic/xhcj/personalCenter/attention/query',
    method: 'post',
    data
  })
}
/**
 * 获取个人 消息列表
 * @param {*pageNo} data 
 * @param {*pageSize} data 
 */
export function getNewsList(params) {
  return request({
    url: '/f/front/investors/byToCId',
    params
  })
}
/**
 * 删除 个人 消息
 * @param {*id} data 
 */
export function delNewsItem(params) {
  return request({
    url: '/f/front/investors/toCDelete',
    params
  })
}
/**
 * 获取 企业 消息
 * @param {*pageNo} data 
 * @param {*pageSize} data 
 */
export function getCompanyList(data) {
  return request({
    url: '/f/front/investors/businessById',
    method: 'post',
    data
  })
}
/**
 * 删除 企业 消息
 * @param {*id} data 
 */
export function delCompanyItem(params) {
  return request({
    url: '/f/front/investors/delete',
    params
  })
}

/**
 * 企业回复问答
 * @param {*id} data 
 * @param {*businessId} data 
 * @param {*replyContent} data 
 */
export function companyReplyUser(data) {
  return request({
    url: '/f/front/investors/reply/add',
    method: 'post',
    data
  })
}