<template>
  <div class="person-news">
    <!-- {{activeId}} -->
    <ul class="person-news-list" v-if="personNewsList.length">
      <li class="person-news-item" v-for="(item,index) in personNewsList" :key="item.id">
        <span class="person-news-item-picture">
          <img :src="`${userDetail.portrait}`" alt="图片" v-if="userDetail.portrait && !errorPg" @error="errorPg = true"/>
          <img src="~@/assets/img/person/person.svg" alt="图片" v-else/>
        </span>
        <div class="person-news-item-content">
          <p class="person-news-item-name" v-if="item.shortName">
            {{userDetail.custName}} <span>问</span> {{item.shortName}}<template v-if="item.stockCode">（{{item.stockCode}}）</template>
          </p>
          <p class="person-news-item-name" v-else>
            {{userDetail.custName}} <span>问</span> {{item.businessName}}
          </p>
          <p class="person-news-item-ask-text">{{item.content}}</p>
          <p class="person-news-item-time">
            {{item.createTime}}
            <span class="person-news-item-reply-del" @click="delNewsItem(item.id, index, item.businessId)" ></span>
            </p>
          <div class="person-news-item-reply" v-if="item.replyContent">
            <p class="person-news-item-name">{{item.shortName}}<template v-if="item.stockCode">（{{item.stockCode}}）</template>回复</p>
            <p class="person-news-item-reply-content">
              {{item.replyContent}}
            </p>
            <p class="person-news-item-reply-bottom">
              <span class="person-news-item-reply-time">{{item.updateTime}}</span>
              <!-- <span class="person-news-item-reply-del" @click="delNewsItem(item.id, index)"></span> -->
            </p>
          </div>
        </div>
        <!-- <span class="person-news-item-del"></span> -->
      </li>
    </ul>
    <NoData v-else />
    <DelModel :delFlag="delFlag" @delCancel="delCancel" @delConfirm="delConfirm"/>
    <p class="loading-more" v-if="!finished && !loadMore && personNewsList.length && form.pageNo>=2" @click="getMore">加载更多</p>
    <p class="load-finish" v-if="finished && personNewsList.length && form.pageNo>2">加载完毕</p>
    <p class="loading-data" v-if="loadMore && personNewsList.length"><img src="@/assets/img/article_list/loading.gif"/>加载中...</p>
  </div>
</template>
<script>
import NoData from '@/components/NoData'
import {getNewsUnread, getNewsList, delNewsItem, updateNewsStatus} from '@/api/person'
import DelModel from '@/components/delModel'
export default {
  data() {
    return {
      userName: '',
      form: {
        pageNo: 1,
        pageSize: 6,
        total: 0
      },
      personNewsList: [],
      loading: true,
      loadMore: false,
      finished: false,
      errorPg: false,
      delFlag: false,
      delId: null,
      delIndex: null,
      loadFlag: false
    };
  },
  components: {NoData,DelModel},
  props: {
    activeId: {
      type: String,
      default: '0'
    },
    personNews: {
      type: Number,
      default: 0
    }
  },
  computed: {
    userDetail() {
      return this.$store.state.localInfoMsg
    }
  },
  watch: {
    activeId(newVal, oldVal) {
      if(oldVal == '1') {
        window.removeEventListener('scroll', this.scrollFns)
      }
      if(newVal == '1') {
        this.updateNewsStatus()
        window.addEventListener('scroll', this.scrollFns)
        this.loadSecond()
      }
    }
  },
  created() {
    if(this.$store.state.token) {
      this.getNewsUnread()
      this.getNewsList()
    }
    
  },
  mounted() {
    
  },
  methods: {
    getNewsList(replace = false) {
      if(!this.loadFlag){
        this.loadFlag = true;
        const { pageNo, pageSize } = this.form
        getNewsList({
          pageNo,
          pageSize
        }).then(res => {
          if(res.data && res.data.length) {
            if (replace) {
              var resItem = res.data[res.data.length-1];
              var noSame = true;
              this.personNewsList.forEach(item => {
                if (item.id == resItem.id) noSame = false;
              });
              if (noSame) {
                this.personNewsList.push(resItem);
              }
            }else {
              this.personNewsList = this.personNewsList.concat(res.data)
            }
            
            if(this.personNewsList.length >= res.count) this.finished = true;
            if(this.activeId == '1') this.updateNewsStatus()
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadMore = false;
          this.loadFlag = false;
        })
      }
    },
    delNewsItem(id, index,businessId) {
      this.delFlag = true;
      this.delId = id;
      this.delBusinessId = businessId
      this.delIndex = index;
    },
    // 取消删除
    delCancel(data){
      this.delFlag = data;
    },
    // 确认删除
    delConfirm(data){
      if(data){
        delNewsItem({
          id:this.delId,
          businessId:this.delBusinessId // 回答问题的企业id
        }).then((res) => {
          if(res.code == 0){
            this.personNewsList.splice(this.delIndex, 1)
            this.getNewsList(true);
            this.delFlag = false;
            this.delId = null;
            this.delIndex = null;
          }
        })
      }
    },
    // 2k屏没有滚动条时加载第二页
    loadSecond(){
      var that = this;
      var loadData = setInterval(function(){
        if(!this.finished && !that.loadFlag && that.pageNo < 2){
          that.scrollFns();
        }
        if(that.pageNo >= 2 || this.finished){
          clearInterval(loadData)
        }
      },100)
    },
    getMore() {
      this.loading = true;
      this.finished = false;
      this.loadMore = true;
      this.form.pageNo++
      this.getNewsList()
    },
    // 滚动事件
    scrollFns(){
      var scrollH = document.body.scrollHeight;
      var clientH = document.body.clientHeight;
      var toTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollH-clientH-toTop<322 && !this.loading && !this.finished && this.form.pageNo<2){
        this.loading = true;
        this.finished = false;
        this.loadMore = true;
        this.form.pageNo++
        this.getNewsList()
      }
    },
    getNewsUnread() {
      getNewsUnread({
        type: 2
      }).then(res => {
        this.$emit('update:personNews', res.data)
      })
    },
    //将未读消息转为已读消息
    updateNewsStatus() {
      var list = []
      this.personNewsList.forEach(item => {
        if(item.status2 == '1') {
          list.push(item.id) 
        }
      })
      if(list.length) {
        updateNewsStatus({
          type: 2,
          id: list.join(',')
        }).then(() => {
          this.personNewsList.forEach(item => {
            if(item.status2 == '1') {
              item.status2 = '2'
            }
          })
        })
      }
    },
  }
};
</script>
