<template>
  <div class="person-follow">
    <div class="person-follow-box">
      <div class="person-follow-title">已关注企业</div>
      <div class="company">
        <div class="company-list" v-if="followCompanyList.length">
          <div class="company-item" v-for="(item, index) in followCompanyList" :key="item.id">
            <img class="company-picture" :src="item.logo" @error="item.error = true" v-if="!item.error&&item.logo" alt="企业图标"/>
            <img class="company-picture" src="~@/assets/img/person/company.svg" alt="" v-else>
            <!-- <span ></span> -->
            <span class="company-name" @click="calendarDeatil(item.id,item.stockCode)">
              <!-- 中信证券（390222） -->
              <span v-if="item.shortName">{{item.shortName}}</span>
              <span v-else>{{item.name}}</span>
              <template v-if="item.stockCode">（{{item.stockCode}}）</template>
            </span>
            <span class="company-close" @click="collectRold(item.id, 11, index)" v-if="item.collectStatus">取消关注</span>
            <span class="company-follow" @click="collectRold(item.id, 11, index)" v-else>关注</span>
          </div>
        </div>
        <NoData shwoType="person" bottom="129px" text="暂无更多关注内容" v-else />
        <!-- <div class="get-more" @click="getMoreCompany" v-if="formCompany.showMore && followCompanyList.length">
          加载更多
        </div> -->
        <p class="loading-more" v-if="!formCompany.finished && !formCompany.loadMore && followCompanyList.length " @click="getMoreCompany">加载更多</p>
        <p class="load-finish" v-if="formCompany.finished && followCompanyList.length ">加载完毕</p>
        <p class="loading-data" v-if="formCompany.loadMore && followCompanyList.length"><img src="@/assets/img/article_list/loading.gif" />加载中...</p>
      </div>
    </div>
    <div class="person-follow-box">
      <div class="person-follow-title">已收藏路演</div>
      <div class="video">
        <div class="video-list" v-if="followRoldList.length">

          <div class="video-item" v-for="(item,index) in followRoldList" :key="item.id">
            <div class="video-item-content">
              <a :to="`/pages/luyan-page/luyanDetails?id=${item.id}`" target="_blank" @click="toDetail(item)">
                <div class="img_box">
                  <!-- <img :src="item.thumb" alt=""> -->
                  <img :src="item.thumb" alt="" @error="item.error = true" v-if="!item.error">
                  <img src="~@/assets/img/indexImg/itembg.png" alt="" v-else>
                  <!-- <i class="img_icon"></i> -->
                  <em class="tip">{{getItemType(item.templateId, item.published)}}</em>
                </div>
              </a>
              <div class="item-info">
                <a :to="`/pages/luyan-page/luyanDetails?id=${item.id}`" target="_blank" @click="toDetail(item)">
                  <h2>{{item.title}}</h2>
                </a>
                <div class="item-footnote">
                  <div class="footnote-scoure">
                    <span style="display:inline-block">
                      <span class="footnote-scoure-name">
                        {{item.officeName}}
                      </span>
                      <template v-if="item.officeCode">
                        （<span class="footnote-scoure-name">{{item.officeCode}}</span>）
                      </template>
                    </span>
                    <span class="collect-start-active" v-if="item.collectStatus" @click="collectRold(item.id,10, index)"></span>
                    <span class="collect-start" v-else @click="collectRold(item.id, 10, index)"></span>
                  </div>
                  <div class="footnote-timer">{{item.published}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NoData shwoType="person" bottom="152px" text="暂无更多收藏内容" v-else />
        <!-- <div class="get-more" @click="getMoreRold" v-if="formRold.showMore && followRoldList.length">
          加载更多
        </div> -->
        <p class="loading-more" v-if="!formRold.finished && !formRold.loadMore && followRoldList.length " @click="getMoreRold">加载更多</p>
        <p class="load-finish" v-if="formRold.finished && followRoldList.length ">加载完毕</p>
        <p class="loading-data" v-if="formRold.loadMore && followRoldList.length"><img src="@/assets/img/article_list/loading.gif" />加载中...</p>

      </div>
    </div>
  </div>
</template>
<script>
import NoData from "@/components/NoData";
import { getRoldTabList, collectRold } from "@/api/rold";
import { getAttentionList } from "@/api/person";
export default {
  data() {
    return {
      tabList: [],

      formCompany: {
        pageNo: 1,
        pageSize: 3,
        total: 0,
        // showMore: true
        finished: false,
        loadMore: false,
        loading: false
      },
      followCompanyList: [],
      formRold: {
        pageNo: 1,
        pageSize: 4,
        total: 0,
        // showMore: true
        finished: false,
        loadMore: false,
        loading: false
      },
      followRoldList: []
    };
  },
  components: { NoData },
  created() {
    if (this.$store.state.token) {
      this.getRoldTabList();
      this.getFollowCompany();
      this.getFollowRold();
    }
  },
  methods: {
    //取消关注
    collectRold(id, type, index) {
      collectRold({
        id,
        type
      }).then(res => {
        if (res.code == 0) {
          if (type == 10) {
            this.followRoldList.splice(index, 1);
            this.getFollowRold(true);
          } else {
            this.followCompanyList.splice(index, 1);
            this.getFollowCompany(true);
          }
        }
      });
    },
    //获取更多关注企业
    getMoreCompany() {
      Object.assign(this.formCompany, {
        loading: true,
        finished: false,
        loadMore: true,
        pageNo: this.formCompany.pageNo + 1
      });
      // this.formCompany.pageNo++;
      this.getFollowCompany();
    },
    //获取关注企业
    getFollowCompany(replace = false) {
      const {pageNo, pageSize} = this.formCompany
      getAttentionList({
        pageNo: pageNo,
        pageSize: pageSize,
        attentionType: 11
      })
        .then(res => {
          if (replace) {
            if (res.data.length) {
              res.data.forEach(item => item.error = false);
              var resItem = res.data[res.data.length-1];
              var noSame = true;
              this.followCompanyList.forEach(item => {
                if (item.id == resItem.id) noSame = false;
              });
              if (noSame) {
                this.followCompanyList.push({
                  ...resItem,
                  collectStatus: true
                });
              }
            }
          } else {
            res.data.forEach(item => {
              item.collectStatus = true;
              this.followCompanyList.push(item);
            });
          }
          console.log(this.followCompanyList);
          if (this.followCompanyList.length >= res.count) this.formCompany.finished = true;
          if(!this.formCompany.finished && pageNo%2 == 0) this.getMoreCompany()
        })
        .finally(() => {
          this.formCompany.loading = false;
          this.formCompany.loadMore = false;
        });
    },
    //获取更多收藏路演
    getMoreRold() {
      Object.assign(this.formRold, {
        loading: true,
        finished: false,
        loadMore: true,
        pageNo: this.formRold.pageNo + 1
      });
      this.getFollowRold();
    },
    //获取收藏路演
    getFollowRold(replace = false) {
      const {pageNo, pageSize} = this.formRold
      getAttentionList({
        pageNo: pageNo,
        pageSize,
        attentionType: 10
      })
        .then(res => {
          if (replace) {
            if (res.data.length) {
              var resItem = res.data[res.data.length - 1];
              var noSame = true;
              this.followRoldList.forEach(item => {
                item.error = false;
                if (item.id == resItem.id) noSame = false;
              });
              if (noSame) {
                this.followRoldList.push({
                  ...resItem,
                  collectStatus: true
                });
              }
            }
          } else {
            res.data.forEach(item => {
              item.error = false;
              item.collectStatus = true;
              this.followRoldList.push(item);
            });
          }
          if (this.followRoldList.length >= res.count) this.formRold.finished = true;
          if(!this.formRold.finished && pageNo%2 == 0) this.getMoreRold()
        })
        .finally(() => {
          this.formRold.loading = false;
          this.formRold.loadMore = false;
        });
    },
    //获取tab列表
    getRoldTabList() {
      getRoldTabList().then(res => {
        this.tabList = res.data;
      });
    },
    getItemType(type, publishedTime) {
      if (new Date(publishedTime).getTime() > new Date().getTime()) return '活动预告'
      var data = this.tabList.filter(item => {
        return item.id == type;
      });
      if (data.length) return data[0].name;
    },
    // 跳转详情
    calendarDeatil(id,stockCode){
      var detailUrl;
      if(stockCode){
        detailUrl = this.$router.resolve({path:'/enterprise/deatil',query:{id:id,columnId:this.$route.query.columnId,stockCode:stockCode}});
      }else{
        detailUrl = this.$router.resolve({path:'/enterprise/deatil',query:{id:id,columnId:this.$route.query.columnId}});
      }
      window.open(detailUrl.href,'_blank')
    },
    // 跳转路演详情
    async toDetail(item) {
      let isIng = false;
      if (item.endTime) {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDate();
        const hour = new Date().getHours();
        const minute = new Date().getMinutes();
        if (year > item.endTime[0]) {
          isIng = false;
        } else {
          isIng = true;
          if (month > item.endTime[1]) {
            isIng = false;
          } else {
            isIng = true;
            if (day > item.endTime[2]) {
              isIng = false;
            } else {
              isIng = true;
              if (hour > item.endTime[3]) {
                isIng = false;
              } else {
                isIng = true;
                if (minute > item.endTime[4]) {
                  isIng = false;
                } else {
                  isIng = true;
                }
              }
            }
          }
        }
      }
      if (item.description && item.description.indexOf('https://wx.easy-board.com.cn') > -1 && (item.startTime || item.endTime) && isIng) {
        if (!this.$store.state.token) {
          this.$router.push("/login");
          return;
        }
        let res;
        if (this.$store.state.token.indexOf("BC_") != -1) {
          res = await this.$store.dispatch("getBusinessInfo");
        } else {
          res = await this.$store.dispatch("getUserInfo");
        }
        const url = item.description?.indexOf('?' > -1) ? `${item.description}&userId=${res.userId}&realName=${res.username}` : `${item.description}?userId=${res.userId}&realName=${res.username}`
        window.open(url, "_blank");
        return
      }
      var detailUrl = this.$router.resolve({path:'/pages/luyan-page/luyanDetails',query:{id:item.id,columnId:452}});
      window.open(detailUrl.href, "_blank");
    }
  }
};
</script>
